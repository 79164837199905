<template>
  <div class="page-common-body order-create-page-container">
    <div class="order-create-container">
      <div class="title">填写并核对订单信息</div>
      <el-form :model="form" :rules="rules" ref="orderForm">
        <el-form-item
          class="order-create__child-container --address-info"
          prop="address_id"
        >
          <div class="border-title">
            <p>收件人信息</p>
          </div>
          <div class="order__address-container">
            <address-management
              @checked="addressOnChecked"
              :theme="$bowser.mobile ? 'component' : 'page'"
            ></address-management>
          </div>
        </el-form-item>
        <el-form-item
          class="order-create__child-container --pay-info"
          prop="pay_type"
        >
          <div class="border-title">
            <p>支付方式</p>
          </div>
          <div class="order__pay-select-container">
            <el-radio-group v-model="form.pay_type">
              <el-radio
                :label="way.val"
                v-for="(way, index) in payWays"
                :key="index"
              >
                <div class="radio-bone">
                  <i
                    class="img-box"
                    :style="{
                      backgroundImage: `url(${way.icon})`
                    }"
                  ></i>
                  <div class="check-icon">
                    <i class="el-icon-check"></i>
                  </div>
                </div>
              </el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item class="order-create__child-container --order-info">
          <div class="border-title">
            <p>订单信息</p>
          </div>
          <div class="order-info__main-table">
            <div class="table__header">
              <p class="header__info-item">商品信息</p>
              <p class="header__info-item">单价</p>
              <p class="header__info-item">数量</p>
              <p class="header__info-item">小计</p>
            </div>
            <div class="table__body">
              <template v-if="waitBillGoods.length > 0">
                <div
                  class="body__option"
                  v-for="item in waitBillGoods"
                  :key="item.id"
                >
                  <template v-if="$bowser.mobile">
                    <div class="cover">
                      <img :src="item.goods.cover.normal | parseImage" alt="" />
                    </div>
                    <div class="info">
                      <p>
                        {{ item.goods.category.name.cn }} {{ item.goods.name }}
                      </p>
                      <p v-if="item.extends">{{ item.extends }}</p>
                      <p>x {{ item.counts }}</p>
                      <p>
                        <strong>￥{{ item.goods.price }}</strong>
                      </p>
                    </div>
                  </template>
                  <template v-else>
                    <div class="body__item --multi">
                      <div class="item__cover">
                        <img
                          :src="item.goods.cover.normal | parseImage"
                          alt=""
                        />
                      </div>
                      <div class="item__name">
                        <p>
                          {{ item.goods.category.name.cn }}
                          {{ item.goods.name }}
                        </p>
                        <p v-if="item.extends">{{ item.extends }}</p>
                      </div>
                    </div>
                    <div class="body__item">￥ {{ item.goods.price }}</div>
                    <div class="body__item">{{ item.counts }}</div>
                    <div class="body__item">￥ {{ item.total_price }}</div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </el-form-item>
        <template v-if="$bowser.mobile">
          <el-form-item
            class="order-create__child-container --order-total-info"
          >
            <div class="total-info__item">
              <p>商品总价</p>
              <p>
                <strong>￥{{ form.price }}</strong>
              </p>
            </div>
            <div class="total-info__item">
              <p>运费</p>
              <p><strong>已包邮</strong></p>
            </div>
            <div class="total-info__item">
              <p>
                <span>共计 {{ form.goodsCounts }} 件商品</span>
                <span
                  >应付金额：<strong>{{ form.price }}</strong></span
                >
              </p>
            </div>
          </el-form-item>
        </template>
      </el-form>
      <div class="fixed-bottom-info-container">
        <div class="fbic__inner">
          <template v-if="$bowser.mobile">
            <p class="total-price-text">
              <strong>￥{{ form.price }}</strong>
            </p>
            <el-button
              class="order-submit-mobile"
              @click="onSubmit"
              :loading="requesting"
              >提交订单</el-button
            >
          </template>
          <template v-else>
            <div class="fbic__child">
              <p class="child__item">
                商品总价 <strong>￥{{ form.price }}</strong>
              </p>
              <p class="child__item">运费 <strong>已包邮</strong></p>
            </div>
            <div class="fbic__child">
              <p class="child__item">
                共计 <span>{{ form.goodsCounts }}</span
                >件商品
              </p>
              <p class="child__item">
                应付金额：<strong class="big">{{ form.price }}</strong>
              </p>
              <el-button
                class="order-submit child__item"
                @click="onSubmit"
                :loading="requesting"
                >提交订单</el-button
              >
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addressManagement from "../address/_manage";
import { payWays } from "@/assets/utils/config";
import { mapMutations, mapState } from "vuex";
import wxtool from "@/assets/utils/wx-tool.js";
export default {
  name: "page-order-create",
  components: {
    addressManagement
  },
  props: ["id"],
  data() {
    return {
      form: {
        address_id: "",
        pay_type: "",
        price: 0,
        goodsCounts: 0
      },
      rules: {
        address_id: [
          {
            required: true,
            message: "请选择地址",
            trigger: "blur"
          }
        ],
        pay_type: [
          {
            required: true,
            message: "请选择支付方式",
            trigger: "change"
          }
        ]
      },
      payWays: payWays,
      waitBillGoods: [],
      requesting: false
    };
  },
  computed: {
    ...mapState("shop", ["carts", "directForm"]),
    cartIds() {
      return Number(this.id) === 0 ? 0 : this.id.split(",");
    },
    isDirect() {
      return this.cartIds === 0;
    }
  },
  watch: {
    carts: {
      handler() {
        this.checkedGoods();
      },
      immediate: true
    }
  },
  mounted() {
    // console.log(payWays, this.cartIds);
  },
  updated() {},
  methods: {
    ...mapMutations("shop", ["DEL_CART"]),
    checkedGoods() {
      let totalPrice = 0;
      let totalCounts = 0;
      if (this.cartIds instanceof Array && this.carts.length > 0) {
        this.waitBillGoods = this.cartIds.map(item => {
          const goods = this.carts.find(
            cart => Number(cart.id) === Number(item)
          );
          totalPrice += Number(goods.total_price);
          totalCounts += Number(goods.counts);
          return goods;
        });
        this.form.price = totalPrice;
        this.form.goodsCounts = totalCounts;
      } else if (this.isDirect && null !== this.directForm) {
        // 直接下单 组装waitBillGoods
        const directOrder = {
          id: Date.now(),
          gid: this.directForm.gid,
          counts: Number(this.directForm.counts),
          extends: this.directForm.extends,
          total_price: Number(this.directForm.total_price),
          goods: this.directForm.goods
        };
        this.waitBillGoods = [directOrder];
        this.form.price = directOrder.total_price;
        this.form.goodsCounts = directOrder.counts;
        this.form.gid = this.directForm.gid;
        this.form.extends = this.directForm.extends;
      }
    },
    addressOnChecked(id) {
      this.form.address_id = id;
    },
    onSubmit() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$refs.orderForm.validate(valid => {
          if (valid) {
            that.form.cartIds = that.cartIds;
            that.form.isMobile = that.$bowser.mobile ? 1 : 0;
            that.$request
              .post("/api/user/order/create", that.form)
              .then(res => {
                const data = res.data;
                if (that.isDirect) {
                  console.log("清空stroe");
                } else {
                  that.form.cartIds.forEach(item => {
                    that.DEL_CART(item);
                  });
                }
                if (that.form.pay_type == 1) {
                  // 支付宝
                  document.write(data.payForm);
                } else if (that.form.pay_type == 2) {
                  // 微信
                  if (that.$bowser.mobile) {
                    if (that.$configs.isInWx) {
                      // 公众号支付
                      const configs = data.mp;
                      wxtool.init().then(wx => {
                        wx.chooseWXPay({
                          timestamp: Number(configs.timeStamp),
                          nonceStr: configs.nonceStr,
                          package: configs.package,
                          signType: configs.signType,
                          paySign: configs.paySign,
                          success: () => {
                            console.log("支付成功");
                            that.$router.push({
                              path: `/order?out_trade_no=${data.order.trade_num}`
                            });
                          }
                        });
                      });
                    } else {
                      // 手机普通浏览器支付
                      window.location.href = data.target_url;
                    }
                  } else {
                    // 跳转结算页面 微信pc扫码
                    that.$router.push({
                      name: "order-wxpay-checkout",
                      params: {
                        order: data.order,
                        qr: data.code_url
                      }
                    });
                  }
                }
              })
              .finally(() => {
                that.requesting = false;
              });
          } else {
            that.requesting = false;
          }
        });
      }
    }
  }
};
</script>

<style></style>
